"use strict";

/**
 * Created by Kayran on 06/12/2016.
 *
 * Este módulo efetua o carregamento do conteúdo do template desejado para uso com Handlebars
 * Por herdar o requestUtils, efetua cacheamento do conteudo do script acelerando usos posteriores.
 *
 *
 */

var handlebarstemplatesrequirer = {};
$.importDependencies([]).done(function () {
  'use strict';

  handlebarstemplatesrequirer = {
    templateName: '',
    templateRequirerURL: '/get_handlebars_template',
    sessionStorageKey: '',
    script_content: '',
    templateIsOK: false,
    cardname: '',
    loadedTemplates: {},
    isMultiTemplates: true,
    hasScriptTags: true,
    callbacks: {},
    args: {},
    /**
     *
     * @param template_name
     * @param cardname NAME OF THE CARD TYPE
     * @param callback THE CALLBACK MUST RECEIVE THE OBJECT OF HANDLEBARS TEMPLATE REQUIRER AND THE OBJECT WHO CALLED IT, PASSED TO INIT AS ARGS AND THE LOADED TEMPLATE NAME
     * @param args ARGUMENT TO SEND TO CALLBACK FUNCTION
     * @param hasScriptTags ARGUMENT TO CALL IF THE TEMPLATE HBS HAS MORE THEN ONE TEMPLATE IN ONE FILE, SPLITTED BY SCRIPT TAGS
     */
    init: function init(template_name, cardname, callback, args, hasScriptTags) {
      this.isMultiTemplates = true;
      if (typeof hasScriptTags !== 'undefined') this.hasScriptTags = hasScriptTags;
      this.templateIsOK = false;
      if (cardname) this.cardname = cardname;
      this.templateName = template_name;
      this.callbacks[template_name] = callback;
      this.args[template_name] = args;
      this.getData(template_name);
    },
    /**
     * THIS FUNCTION IS ANOTHER OPTION TO INIT, CREATED TO MAKE A MULTIPLE INSERTION
     * @param template_name
     * @param callback THE CALLBACK MUST RECEIVE THE OBJECT OF HANDLEBARS TEMPLATE REQUIRER AND THE OBJECT WHO CALLED IT, PASSED TO INIT AS ARGS AND THE LOADED TEMPLATE NAME
     * @param args ARGUMENT TO SEND TO CALLBACK FUNCTION
     * @param hasScriptTags
     */
    addTemplate: function addTemplate(template_name, callback, args, hasScriptTags) {
      if (typeof hasScriptTags !== 'undefined') this.hasScriptTags = hasScriptTags;else this.hasScriptTags = false;
      this.callbacks[template_name] = callback;
      this.args[template_name] = args;
      this.isMultiTemplates = true;
      this.getData(template_name);
    },
    getParameters: function getParameters() {
      return {
        cardname: this.cardname,
        filename: this.templateName
      };
    },
    reload: function reload(filters) {
      // this.templateIsOK = false;
      // this.getData();
    },
    getData: function getData(template_name) {
      this.templateIsOK = false;
      var parameters;
      if (template_name) parameters = {
        cardname: this.cardname,
        filename: template_name
      };else parameters = this.getParameters();
      this.sessionStorageKey = 'handlebarstemplatesrequirer' + JSON.stringify(parameters);
      requestUtils.requestData(this, 'handlebarstemplatesrequirer' + template_name + this.cardname, this.templateRequirerURL, parameters, this.sessionStorageKey);
    },
    handleData: function handleData(script_content) {
      if (script_content.status === 'DATA') {
        this.templateIsOK = true;
        if (!this.isMultiTemplates) {
          this.script_content = Handlebars.compile(script_content.message.content);
        } else {
          var template_name = script_content.message.filename;
          if (this.hasScriptTags) {
            this.proccessScriptTemplates(script_content.message.content, script_content.message.filename);
          } else {
            this.loadedTemplates[script_content.message.filename] = Handlebars.compile(script_content.message.content);
          }
          if (typeof this.callbacks[template_name] !== 'undefined') {
            /**
             * THIS PORTION OF CODE CALL THE CALLBACK FUNCTION OF THE GIVEN TEMPLATE, PASSING THE HANDLEBARS TEMPLATE REQUIRER OBJECT, THE OBJECT WHO CALLED THE TEMPLATE AND THE TEMPLATE NAME
             */
            this.callbacks[template_name](this, this.args[template_name], template_name);
          }
        }
        return true;
      }
      return false;
    },
    /**
     * THIS FUNCTION GET THE LOADED TEMPLATE AND CONTEXT TO MAKE THE HTML FOR THE PAGE
     * @param context {*} OBJECT WITH VARIABLE PARAMS TO TEMPLATE
     * @param filename string name of the file you wanna use
     * @param template_name only if your template uses script, you send a template name, and use it
     * @returns {*} TEMPLATE HTML TO PUT IN PAGE
     */
    useTemplate: function useTemplate(context, filename, template_name) {
      if (this.templateIsOK) {
        if (filename) {
          if (template_name) return this.loadedTemplates[filename][template_name](context);else return this.loadedTemplates[filename](context);
        } else return this.loadedTemplates[this.templateName](context);
      } else return false;
    },
    /**
     * this function receive the content of HBS file and the filename, process the content
     * registering all partials on file and compiling all templates of it.
     * @param content: content of HBS file
     * @param filename: name of file
     */
    proccessScriptTemplates: function proccessScriptTemplates(content, filename) {
      var $div_hbs_scripts = $('<div class="hbs_scripts">').appendTo('body');
      $div_hbs_scripts.html(content);
      var $array_partials = $div_hbs_scripts.find('script[id^=partial]');
      var $array_templates = $div_hbs_scripts.find('script:not([id^=partial])');
      var idx;
      for (idx = 0; idx < $array_partials.length; idx++) {
        var partial_content = $($array_partials[idx]).html();
        Handlebars.registerPartial($array_partials[idx].id, partial_content);
      }
      this.loadedTemplates[filename] = {};
      for (idx = 0; idx < $array_templates.length; idx++) {
        var template_content = $($array_templates[idx]).html();
        this.loadedTemplates[filename][$array_templates[idx].id] = Handlebars.compile(template_content);
      }
    }
  };

  /**
   * Por ser acionado por vários locais e poder ser acionado para mais de um template ao mesmo tempo, o mesmo não será auto iniciado
   */
}).fail(function (error) {
  console.error(error);
});